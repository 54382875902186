<template>
  <div>
    <b-card>
      <b-row class="match-height">
        <b-col>
          <b-card>
            <h3 class="d-flex justify-content-center align-items-center mb-2">
              Añadir saldo de EMIDA a WallEat
            </h3>
            <validation-observer
              #default="{ handleSubmit }"
              ref="emidaFormObserver"
            >
              <h4
                class="mb-2"
              >
                Información adicional de la recarga
              </h4>
              <!-- Form -->
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >

                <validation-provider
                  #default="validationContext"
                  name="amount"
                  rules="required"
                >
                  <b-form-group
                    label="Monto"
                    label-for="amount"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <i class="fas fa-dollar-sign" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="amount"
                        v-model="emidaFund.amount"
                        type="number"
                        trim
                      />
                    </b-input-group>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  #default="validationContext"
                  name="payment_method"
                  rules="required"
                >
                  <b-form-group
                    label="Tipo de cuenta"
                    label-for="payment_method"
                  >
                    <b-input-group>
                      <b-form-select
                        v-model="emidaFund.account_type"
                        :options="emidaOptions"
                      />
                    </b-input-group>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    :disabled="loading"
                  >
                    <b-spinner
                      v-if="loading"
                      small
                    />
                    Confirmar
                  </b-button>
                </div>

              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
      loading: false,
      emidaOptions: [
        { value: 'emida_b', text: 'Pago de servicios' },
        { value: 'emida_a', text: 'Recargas de saldo' },
      ],
      emidaFund: {
        account_type: null,
        amount: 0,
      },
    }
  },
  methods: {
    ...mapActions('emidaProducts', ['addEmidaFunds']),
    onSubmit() {
      this.$refs.emidaFormObserver.validate().then(success => {
        if (success) {
          this.loading = true
          this.addEmidaFunds(this.emidaFund)
            .then(() => {
              this.$swal({
                title: '¡Saldo emida añadido exitosamente!',
                text: 'Gracias.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btnx btn-primary',
                },
                buttonsStyling: false,
              })
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.response.data.messages.base[0],
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
